import { gql, useQuery } from "@apollo/client";
import React, {useState, useEffect} from "react";
import { isBrowser } from "../../components/Auth";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";

const SHIPPING_POLICY = gql`
  query getShippingPolicy($language: LanguageCode) @inContext(language: $language) {
    shop {
      shippingPolicy {
        body
        title
      }
    }
  }
`;

const ShippingPolicy = () => {
  const [lang, setLang] = useState(null);
  useEffect(() => {
    if (isBrowser) {
      setLang(window.localStorage.getItem("langSelect"))
    }
  }, [])
  const { loading, data } = useQuery(SHIPPING_POLICY, {
    variables: {
      language: lang
    }
  });

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <div className="container px-4 mx-auto my-8">
          <h1 className="font-bold text-3xl mb-4">{data.shop.shippingPolicy?.title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: data.shop.shippingPolicy?.body }}
          ></div>
        </div>
      )}
    </Layout>
  );
};

export default ShippingPolicy;
